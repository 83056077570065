<template>
  <div class="header mb-5">
    <v-app-bar app color="transparent" flat height="75">
      <v-container class="py-0 fill-height">
        <div class="d-flex align-center">
          <v-btn
            fab
            x-small
            color="primary"
            elevation="1"
            @click="$store.state.menu = !$store.state.menu"
          >
            <v-icon>
              {{ !$store.state.menu ? "format_list_bulleted" : "more_vert" }}
            </v-icon>
          </v-btn>
        </div>
        <v-spacer> </v-spacer>
        <img width="120" class="logo" src="@/assets/logo.png" />
        <v-spacer> </v-spacer>
        <div
          :class="[{ 'primary--text': $store.state.balance > 0 }, { 'error--text': $store.state.balance <= 0 }]"
          class="d-flex align-center font-weight-bold"
        >
          الرصيد
          <span class="mx-2">{{
            Intl.NumberFormat("en-US").format($store.state.balance.toFixed(1))
          }}</span>
          <v-icon :color="$store.state.balance <= 0  ? 'error' : 'primary'">payments</v-icon>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {

  .language {
    width: 50px;
    font-size: 13px;
    .v-select__selections {
      font-weight: bold;
      font-size: 14px;
      padding: 0;
    }
    fieldset {
      border: none !important;
    }
    .v-input__icon {
      display: none;
    }
    .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
      > .v-input__control
      > .v-input__slot,
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      padding: 0;
    }
  }
  //---- language

  .lang-item {
    min-height: 28px !important;
    font-size: 12px !important;
  }
}
</style>
<script>
export default {
  name: "AppHeader",
  data: () => ({
    lang: {
      val: {
        name: "Arbic",
        shortCut: "Ar",
        icon: "ar.png",
      },
      langs: [
        {
          id: 1,
          name: "Arbic",
          shortCut: "Ar",
          icon: "ar.png",
        },
        {
          id: 2,
          name: "English",
          shortCut: "En",
          icon: "en.png",
        },
      ],
    },
    items: [
      { text: "البروفيل", icon: "mdi-account" },
      { text: "تسجيل الخروج", icon: "logout" },
    ],
  }),
  methods: {
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      }, 500);
    },
  },
  computed: {},
};
</script>
